<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-x-xs gap-y-xs lg:gap-x-sm">
    <GridItem col-span="1" :breakpoints="{}" v-for="(item, index) in loadingArray" :key="index" v-if="isLoading">
      <ProductCard
        link-path="/"
        :is-placeholder="true"
        @card-branch-select="setBranchTray"
        :content="{
          title: '',
          image: '',
          desktopImage: '',
          quantity: 1,
          total: 2,
          vat: true,
          hasVat: true,
          unit: '',
          personalisedText: '',
          priceEach: 10,
          offerPriceEach: 'each',
          alert: '',
          collection: true,
          subtitle: '',
        }"
        :branch-selected="selectedBranch ? true : false"
        :signed-in="false"
        :usp="{ availability: 'collect', dropshipLink: '/', branch: '', branchNumber: '' }"
        product-i-d="2"
        :is-tally="false"
        :is-enquire="false"
        :greener-option="{ variant: 'none' }"
        :image-loading="index < 3 ? 'eager' : 'lazy'"
        data-test-id="plpProductPods"
        data-fs="plpProductPods"
      />
    </GridItem>

    <GridItem
      col-span="1"
      :breakpoints="{}"
      v-for="(item, index) in categoryBCData.items"
      :key="index"
      v-if="!isLoading && categoryBCData.items.length > 0"
    >
      <ProductCard
        v-if="item"
        @card-clicked="fireSelectItemEvent(item)"
        @card-is-tally-product="tallyHandler(item)"
        :link-path="transformItem(item).link"
        :is-placeholder="false"
        @card-branch-select="setBranchTray"
        :content="transformItem(item).product"
        :branch-selected="selectedBranch ? true : false"
        :signed-in="user ? true : false"
        :user-name="user ? user.profile?.first_name : ''"
        @add-item-to-basket="({ quantity, productID }) => handleAddItemToBasket({ quantity, productID })"
        :usp="{
          availability: transformItem(item).availability as
            | 'collect'
            | 'delivery'
            | 'quote'
            | 'dropship'
            | 'outOfStock',
          dropshipLink: '/',
          branch: selectedBranch ? selectedBranch.name : '',
          branchNumber: selectedBranch ? selectedBranch?.contacts?.phone : '',
        }"
        :product-i-d="transformItem(item).id"
        :is-tally="transformItem(item).isTally"
        :is-enquire="false"
        :greener-option="{ variant: transformItem(item).greenerOption }"
        :image-loading="index < 3 ? 'eager' : 'lazy'"
        :link-component="NuxtLink"
        @card-sign-in="setLoginTray"
        @dropship="setDropshipTray"
        data-test-id="productPods"
      />
    </GridItem>

    <GridItem col-span="2" :breakpoints="{ lg: '4' }" v-if="!isLoading && categoryBCData.items.length === 0">
      <CategoryNotFound />
    </GridItem>
  </div>

  <Pagination
    :key="page"
    class="my-lg"
    :items="pagesLength"
    :page="page"
    :buttons="3"
    :per-page="10"
    v-if="!isSearch && totalFoundItems > 0"
    @update:page="(value) => updatePage(value)"
    data-test-id="plpPagination"
    data-fs="plpPagination"
  />

  <Pagination
    :key="paginationKey"
    class="my-lg"
    :items="pagesLength"
    :page="currentSearchPage"
    :buttons="3"
    :per-page="10"
    v-if="isSearch && totalFoundItems > 0"
    @update:page="(value) => updatePage(value)"
  />

  <LazyTallyTray
    v-if="tallyProduct"
    :product-info="tallyProduct"
    :product-default-pricing="tallyProduct"
    :price="tallyProductPrice"
    :selected-branch-id="selectedBranchId"
  />
</template>

<script lang="ts" setup>
import type { CategoryGridProps } from "./categoryTypes";
import { useGA4EcommerceEvents, ViewItemListEvent } from "mkm-gtm";
import { ROUTES } from "~/helpers/routes/routes";
import { productUnitTranslation, type ProductDetailsType } from "~/utils/productUnitTranslation";
const { generateBreadcrumbs } = useCategoryPage();
const NuxtLink = resolveComponent("NuxtLink");
const { pushGA4EcommerceEvent, GA4EcommerceEvents } = useGA4EcommerceEvents();
const route = useRoute();
const routeSlug = ref(route.query.q === undefined ? "" : decodeURIComponent(route.query.q as string));

const getProductLink = (item: any) => {
  const link = item?.customAttrs
    ?.filter((attribute: Record<string, string>) => attribute.name === "url")[0]
    ?.values[0]?.slice();

  return link.slice(Math.max(0, link.indexOf("/product")));
};

const { data: categoryBCData } = useCategory();
const { selectedBranch, selectedBranchId } = useBranches();
const { setBranchTray, isVatIncluded, setBasketTray, setDropshipTray, setLoginTray, setTallyTray } = useUIState();
const { filters, filtersChange, hasFilters } = useFilters();
const { currentPage, currentSearchPage } = useCategoryPage();
const { user, userCategory } = useUser();

const props = defineProps<CategoryGridProps>();

const calculatePageType = (): ViewItemListEvent["page_type"] => {
  if (route.path.startsWith(ROUTES.SEARCH)) {
    return "search";
  }

  if (route.path.startsWith(ROUTES.CATEGORY)) {
    return "category";
  }

  return "other";
};

const page = ref(currentPage.value ?? 1);
const searchPage = ref(currentSearchPage.value ?? 1);
const paginationKey = ref(0);

const tallyProduct = ref<any>();
const tallyProductPrice = ref<any>();

const { addCartItemQuantity } = useCart();

const handleAddItemToBasket = async ({ quantity, productID }: { quantity: number; productID: string }) => {
  await addCartItemQuantity(productID, quantity);
  setBasketTray(true);
};

const getCustomAttrValue = (product: any, attrName: any) => {
  const attr = product.customAttrs.find((attr: any) => attr?.name === attrName);
  return attr ? attr?.values[0] : null;
};

const findProductPrice = (product: any, prices: any) => {
  if (product?.itemId.id && prices && prices.length > 0) {
    return prices.find((it: any) => it.product_sku === product.itemId.id);
  }
  return null;
};

const categories = ref<{ count: number; id: string; name: string; parentId: string | null }[]>([]);
const getStockType = (availabilityFlag: number) => {
  return availabilityFlag === 1 ? "BRANCH STOCKED" : availabilityFlag === 4 ? "DROPSHIP" : null;
};

const item_categories = generateBreadcrumbs();
const item_list_id = item_categories[item_categories.length - 1].title;

// eslint-disable-next-line sonarjs/cognitive-complexity
onMounted(() => {
  categories.value = categoryBCData.value.facetResult.fields.find(
    (item: { [key: string]: string }) => item?.id === "category",
  )?.values;
  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
  setTimeout(async () => {
    const pageType = calculatePageType();
    await pushGA4EcommerceEvent(GA4EcommerceEvents.VIEW_ITEM_LIST, {
      logged_in: user.value !== null || false,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_owning_branch: Number(user.value?.account?.branch_id) ?? null,
      account_type: user.value?.account.type ?? null,
      category: categories.value[0]?.name ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      page_type: pageType ?? null,
      search_term: routeSlug.value !== "" || null ? routeSlug.value : null,
      search_results: pageType === "category" ? null : props.totalFoundItems,
      ecommerce: {
        currency: "GBP",
        item_list_name: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
        // it might be best to leave the item param as any type for flexibility here
        items: categoryBCData.value.items.map((item: any, index: number) => {
          return {
            item_list_id: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
            item_list_name: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
            item_id: item?.itemId.id ?? null,
            item_name: item.displayName ?? null,
            bigcommerce_item_id: getCustomAttrValue(item, "bcid"),
            index: index,
            price: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
            price_ex_vat: Number.parseFloat(getCustomAttrValue(item, "price")) ?? null,
            quantity: 1,
            stock_type: getStockType(Number(getCustomAttrValue(item, "availability_flag"))) ?? null,
          };
        }),
      },
    });
  }, 1200);
});

// might be best to use any type for the item param for flexibility
// eslint-disable-next-line sonarjs/cognitive-complexity
const fireSelectItemEvent = async (item: any) => {
  const pageType = calculatePageType();
  await pushGA4EcommerceEvent(GA4EcommerceEvents.SELECT_ITEM, {
    logged_in: user.value !== null || false,
    user_id: user.value?.id ?? null,
    customer_category_1: userCategory?.[0] === "NTrade" ? "NON-TRADE" : "TRADE",
    customer_category_2: userCategory?.[1] ?? null,
    customer_category_3: userCategory?.[2] ?? null,
    account_owning_branch: Number(user.value?.account?.branch_id) ?? null,
    account_type: user.value?.account.type ?? null,
    category: categories.value[0]?.name ?? null,
    selected_branch: selectedBranch.value?.name ?? null,
    price: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
    price_ex_vat: Number.parseFloat(getCustomAttrValue(item, "price")) ?? null,
    stock_type: getStockType(Number(getCustomAttrValue(item, "availability_flag"))) ?? null,
    ecommerce: {
      currency: "GBP",
      value: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
      item_list_name: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
      item_list_id: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
      items: [
        {
          item_list_id: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
          item_list_name: pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null,
          item_id: item?.itemId.id ?? null,
          item_name: item?.displayName ?? null,
          bigcommerce_item_id: getCustomAttrValue(item, "bcid"),
          price: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
          price_ex_vat: Number.parseFloat(getCustomAttrValue(item, "price")) ?? null,
          quantity: 1,
        },
      ],
    },
  });
};

const tallyHandler = (item: any) => {
  tallyProduct.value = item;
  tallyProductPrice.value = findProductPrice(item, props.prices);

  setTimeout(() => {
    setTallyTray();
  }, 100);
};

// eslint-disable-next-line no-unused-vars
const updatePage = (value: number) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  setTimeout(() => {
    props.isSearch ? (searchPage.value = value) : (page.value = value);
  }, 500);
};

const loadingArray = Array.from({ length: 6 }, (_, i) => i);

// eslint-disable-next-line no-unused-vars
const pagesLength = computed(() => Math.ceil(props?.totalFoundItems / props.itemsPerPage) || 1);

const badgeFields = (customAttrs: Record<string, string>[]) => {
  const badges = new Set(["cf_icon-green", "cf_icon-materials", "cf_icon-energy", "cf_icon-planet"]);

  const badgeIcons = customAttrs
    .filter(({ name, values }) => badges.has(name) && values[0].includes("Y"))
    .map(({ name }) => name.replace("cf_icon-", ""));

  if (badgeIcons.length > 1) {
    return "green";
  } else if (badgeIcons.length === 0) {
    return "none";
  } else {
    return badgeIcons[0] as "green" | "none" | "energy" | "materials" | "planet";
  }
};

const availability = (customAttrs: Record<string, string>[]) => {
  const availability = customAttrs.find(({ name }) => name === "availability_flag");

  const availabilityClass: { [key: number]: string } = {
    0: "collect",
    1: "delivery",
    2: "quote",
    3: "outOfStock",
    4: "dropship",
  };

  return availability ? availabilityClass[Number(availability.values[0])] : "collection";
};

watch(page, async (value) => {
  await nextTick();
  // @ts-ignore
  await props.fetchNewPage(value);
});

watch(searchPage, async (value) => {
  await nextTick();
  paginationKey.value++;
  // @ts-ignore
  await props.fetchNewPageOnSearchPage(value);
});

watch(
  [user, selectedBranch, filters, isVatIncluded],

  async (updatedData, initialData) => {
    if (filtersChange.value) {
      if (updatedData === initialData) {
        return;
      }

      filtersChange.value = false;
      hasFilters.value = true;
      // @ts-ignore
      props.isSearch ? await props.fetchNewPageOnSearchPage(1) : await props.fetchNewPage(1);
    } else if (selectedBranch.value || user.value || isVatIncluded.value) {
      if (updatedData[1] === initialData[1]) {
        return;
      }

      // @ts-ignore
      props.isSearch ? await props.fetchNewPageOnSearchPage(1) : await props.fetchNewPage(1);
    }
  },
  { deep: true },
);

const transformItem = (item: any) => {
  return reactive({
    availability: availability(item.customAttrs),
    greenerOption: badgeFields(item.customAttrs),
    link: getProductLink(item),
    id: item.itemId.id,
    product: {
      image: item.imageSet.thumbnail.link.href ?? "/product/placeholder-product.png",
      desktopImage: item.imageSet.original.link.href ?? "/product/placeholder-product.png",
      title: item.displayName,
      subtitle: "",
      quantity: 1,
      total: 2,
      vat: isVatIncluded.value,
      hasVat: isVatIncluded.value,
      unit: productUnitTranslation(`${findProductPrice(item, props.prices)?.unit}` as ProductDetailsType) as string,
      personalisedText: "",
      priceEach: computed(() =>
        isVatIncluded.value
          ? Number(findProductPrice(item, props.prices)?.price_inc_vat) ||
            Number(getCustomAttrValue(item, "price_inc_vat"))
          : Number(findProductPrice(item, props.prices)?.price) || Number(getCustomAttrValue(item, "price")),
      ),
      offerPrice: isVatIncluded.value
        ? Number(findProductPrice(item, props.prices)?.offer_price_inc_vat) ||
          Number(getCustomAttrValue(item, "offer_price_inc_vat"))
        : Number(findProductPrice(item, props.prices)?.offer_price) || Number(getCustomAttrValue(item, "offer_price")),
      offerPriceEach:
        (productUnitTranslation(
          `${findProductPrice(item, props.prices)?.offer_unit}` as ProductDetailsType,
        ) as string) ||
        (productUnitTranslation(`${getCustomAttrValue(item, "offer_unit")}` as ProductDetailsType) as string),
      alert: "",
      collection: true,
    },
    isTally: getCustomAttrValue(item, "is_tally") === "0" ? false : true,
  });
};
</script>
